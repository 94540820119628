import React from 'react'
import "./skills.css"
import Skill1 from './Skill1'
import Skill2 from './Skill2'

const Skills = () => {
  return (
    <section className="skills section" id="skills">
        <h2 className="section__title">Skills</h2>
        <span className="section__subtitle">My technical level</span>

        <div className="skills__container container grid">
            <Skill1 />
            <Skill2 />
        </div>
    </section>
  )
}

export default Skills