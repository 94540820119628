import React from 'react'

const Skill1 = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Engineering</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">CAD</h3>
                        <span className="skills__level">Advanced</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">PLM</h3>
                        <span className="skills__level">Advanced</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">SAP ERP</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

            </div>

            <div className="skills__group">
                
                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">Mechanical Design</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">Electronic Design</h3>
                        <span className="skills__level">Beginner</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">Project Management</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>


            </div>

            
        </div>
    </div>
  )
}

export default Skill1