import React from 'react'

const Skill2 = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">IT</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">Python</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">Machine Learning</h3>
                        <span className="skills__level">Beginner</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">VBA</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

            </div>

            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">MATLAB</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">Networking</h3>
                        <span className="skills__level">Beginner</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className="bx bx-check-circle"></i>

                    <div>
                        <h3 className="skills__name">WebDev</h3>
                        <span className="skills__level">Beginner</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Skill2