import React from 'react'
import "./footer.css"
import VCF from "../../assets/droll.vcf"

const Footer = () => {
  return (
    <footer className="footer" id="footer">
        <div className="footer__container container">
            <h1 className="footer__title">Leonie Droll</h1>

            <ul className="footer__list">
                <li>
                    <a href="#about" className="footer__link">About</a>
                </li>

                <li>
                    <a href="#skills" className="footer__link">Skills</a>
                </li>

                <li>
                    <a href="#portfolio" className="footer__link">Portfolio</a>
                </li>
            </ul>

            <div className="footer__social">
                <a href="https://www.linkedin.com/" className="footer__social-link" target="blank" rel="noopener">
                    <i className="bx bxl-linkedin"></i>
                </a>
                <a href="mailto:leoniedroll@web.de" className="footer__social-link" target="blank" rel="noopener">
                    <i className="bx bxs-envelope"></i>
                </a>
                <a href={VCF} download="Leonie-Droll.vcf" className="footer__social-link" target="blank" rel="noopener">
                    <i className="bx bxs-contact"></i>
                </a>
            </div>

            <span className="footer__copy">
                &#169; Leonie Droll. All rights reserved.
            </span>
        </div>
    </footer>
  )
}

export default Footer