import React from 'react'
import VCF from "../../assets/droll.vcf"

const Social = () => {
  return (
    <div className="home__social">
        <a href="https://www.linkedin.com/" className="home__social-icon" target="blank" rel="noopener">
        <i className="uil uil-linkedin-alt"></i>
        </a>
        <a href="https://www.github.com/ldroll" className="home__social-icon" target="blank" rel="noopener">
        <i className="uil uil-github-alt"></i>
        </a>
        <a href={VCF} download="Leonie-Droll.vcf" className="home__social-icon" target="blank" rel="noopener">
            <i className="uil uil-user-square"></i>
        </a>
    </div>
  )
}

export default Social