import React from 'react'

const Info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
            <i class='bx bx-briefcase about__icon'></i>
            <h3 className="about__title">Experience</h3>
            <span className="about__subtitle">5 Years Industry</span>
        </div>

        <div className="about__box">
            <i class='bx bx-abacus about__icon'></i>
            <h3 className="about__title">Excellence</h3>
            <span className="about__subtitle">#1 Graduate</span>
        </div>

        <div className="about__box">
            <i class='bx bx-group about__icon'></i>
            <h3 className="about__title">Project Lead</h3>
            <span className="about__subtitle">€500K+ Budget</span>
        </div>
    </div>
  )
}

export default Info