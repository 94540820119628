import Work from "../../assets/work.jpg"
import Mopeds from "../../assets/Mopeds.jpg"
import Wordclock from "../../assets/wordclock.jpg"
import EBSAL from "../../assets/H2-System.jpg"
import PPL from "../../assets/PPL.jpg"
import HomeServer from "../../assets/home-server.png"
import Server from "../../assets/server.png"
import Pi from "../../assets/ML-Pi.jpg"
import Website from "../../assets/react.png"

export const projectsData = [
    {
        id:1,
        image: Mopeds,
        title: 'Motorcycle Restauration',
        category: 'Engineering',
        description: 'Kreidler Florett (1973), NSU Quickly (1955)'
    },
    {
        id:2,
        image: Work,
        title: 'EBSAL Aircraft Hydrogen System',
        category: 'Engineering',
        description: 'Blank paper to 40kW fuel cell demonstrator'
    },
    {
        id:3,
        image: PPL,
        title: 'Private Pilot License',
        category: 'Other',
        description: 'PPL(A)-SEP, BZF I, NFQ'
    },
    {
        id:4,
        image: Work,
        title: 'Mirror Cabinet',
        category: 'Engineering',
        description: 'Custom Arduino-controlled solution'
    },
    {
        id:5,
        image: Wordclock,
        title: 'Wordclock',
        category: 'Engineering',
        description: 'Customizable ESP32-based version'
    },
    {
        id:6,
        image: Server,    
        title: 'Home Server Setup',
        category: 'IT',
        description: 'Local VE with backup NAS'
    },
    {
        id:7,
        image: Work,
        title: 'Smart Home Setup',
        category: 'IT',
        description: 'Open-source solution incl. Z2MQTT'
    },
    {
        id:8,
        image: Pi,
        title: 'Machine Learning Edge Application',
        category: 'IT',
        description: 'Defect recognition deployed on R-Pi'
    },
    {
        id:9,
        image: Website,
        title: 'Personal Website',
        category: 'IT',
        description: 'React website from template'
    },
];

export const projectsNav = [
    {
        name: 'all',
    },
    {
        name: 'Engineering',
    },
    {
        name: 'IT',
    },
    {
        name: 'Other',
    }
]